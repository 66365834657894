<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar text-right bg-white p-5">
        <!--begin: User Bar -->
        <QuickUser></QuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
    .dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>

<script>
import QuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue'
import i18nService from '@/core/services/i18n.service.js'

export default {
    name: 'KTTopbar',
    data() {
        return {
            languageFlag: '',
            languages: i18nService.languages
        }
    },
    components: {
        QuickUser
    },
    methods: {
        onLanguageChanged() {
            this.languageFlag = this.languages.find(val => {
                return val.lang === i18nService.getActiveLanguage()
            }).flag
        }
    },
    computed: {
        getLanguageFlag() {
            return this.onLanguageChanged()
        }
    }
}
</script>
