<template>
    <div>
        <a
            href="#"
            class="btn btn-icon btn-clean btn-lg w-40px h-40px"
            id="kt_quick_user_toggle"
            data-toggle="tooltip"
            data-placement="right"
            data-container="body"
            data-boundary="window"
        >
            <span class="symbol symbol-30 symbol-lg-40">
                <img class="symbol-label" :src="currentUser.avatar" alt="" />
            </span>
        </a>

        <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 class="font-weight-bold m-0">
                    {{ $t('AUTH.GENERAL.MY_PROFILE') }}
                    <small class="text-muted font-size-sm ml-2">{{ $store.getters.getRole.name }}</small>
                </h3>
                <button
                    type="button"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                    @click="closeOffcanvas"
                >
                    <i class="ki ki-close icon-xs text-muted"></i>
                </button>
            </div>
            <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative">
                <div class="d-flex align-items-center mt-5">
                    <div class="mr-5 w-25">
                        <img class="symbol-label" :src="currentUser.avatar" style="border-radius: 10px;" alt="" />
                        <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column w-75">
                        <a
                            :href="`${VUE_APP_PLATEFORME_URL}/account`"
                            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                        >
                            {{ currentUser.firstname }} {{ currentUser.lastname }}
                        </a>
                        <span class="text-muted mt-1" v-if="currentUser.customer">{{ currentUser.customer.name }}</span>
                        <!-- <div class="navi mt-2" v-if="currentUser.email">
                            <a href="#" class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                    <span class="navi-icon mr-1">
                                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                                            <inline-svg src="/media/svg/icons/Communication/Mail-notification.svg" />
                                        </span>
                                    </span>
                                    <span class="navi-text text-muted">
                                        {{ currentUser.email }}
                                    </span>
                                </span>
                            </a>
                        </div> -->
                        <div v-else class="mb-5"></div>
                        <a :href="`${VUE_APP_PLATEFORME_URL}/logout`" class="btn btn-light-primary btn-bold mt-2">
                            {{ $t('AUTH.GENERAL.LOGOUT') }}
                        </a>
                    </div>
                </div>
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="navi navi-spacer-x-0 p-0">
                    <a :href="`${VUE_APP_PLATEFORME_URL}/account`" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-success">
                                        <inline-svg src="/media/svg/icons/General/Settings-2.svg" />
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">{{ $t('AUTH.QUICKUSER.MY_ACCOUNT') }}</div>
                                <div class="text-muted">
                                    {{ $t('AUTH.QUICKUSER.MY_ACCOUNT_DESC') }}
                                </div>
                            </div>
                        </div>
                    </a>
                    <a :href="`${VUE_APP_PLATEFORME_URL}`" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <img style="max-width:80%;margin: 0 auto" src="/media/logos/plateforme-sm.png" />
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">{{ $t('AUTH.QUICKUSER.PLATFORM') }}</div>
                                <div class="text-muted">
                                    {{ $t('AUTH.QUICKUSER.GO_TO_PLATFORM') }}
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="separator separator-dashed my-7"></div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
    overflow: hidden;
}
</style>

<script>
import { mapGetters } from 'vuex'
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'

export default {
    mounted() {
        // Init Quick User Panel
        KTLayoutQuickUser.init(this.$refs['kt_quick_user'])
    },
    data() {
        return {
            VUE_APP_PLATEFORME_URL: process.env.VUE_APP_PLATEFORME_URL
        }
    },
    methods: {
        closeOffcanvas() {
            new KTOffcanvas(KTLayoutQuickUser.getElement()).hide()
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser',
            isAuthenticated: 'isAuthenticated',
            offers: 'listQuickUserOffers'
        })
    }
}
</script>
